@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
@tailwind base;
@tailwind components;
@tailwind utilities;

.player-wrapper {
  position: relative;
  padding-top: 140%; /* Player ratio: 100 / (1280 / 720) */
  padding-right: 120%; /* Player ratio: 100 / (1280 / 720) */
}

.react-player {
  position: absolute;
  top: 0;
  left: 0;
}

@layer components {
  
  .lnk-naranja {
    @apply bg-white font-bold rounded-3xl text-secondary border-2 border-orange-500 mr-12 hover:bg-secondary hover:bg-opacity-25;
    @apply lg:px-6 lg:py-2 lg:text-xl md:px-2 md:py-3 md:text-lg px-1 py-2 text-sm mr-1;
  }

  .lgo-blanco {
    @apply bg-white text-black font-bold;
    @apply lg:text-[40px]  md:text-[35px]  text-[20px];
  }

  /************************************************************************/
   /* ************************** NAVBAR ************************************/ 

  .navbar-container-logo {
    @apply bg-primary w-1/4 lg:w-2/4;
    @apply md:pr-6 lg:pr-20 xl:-mr-48;
  }

  .navbar-menu {
    @apply rounded-none h-auto ; 
    @apply md:mt-2 lg:ml-16 xl:ml-0 xl:mt-3 xl:mr-20;
  }

  .navbar-menu-opt{
    @apply content-center text-center justify-center text-gray-800 hover:bg-gray-800 font-normal;
    @apply md:text-base lg:text-lg lg:mt-2 lg:pl-4 xl:text-lg xl:mt-3 ; 
  }
  
  .navbar-container-boton {
    @apply hidden md:flex;
  }

  .navbar-boton {
    @apply bg-primary text-center text-base justify-center m-1 font-semibold text-secondary rounded-3xl hover:bg-opacity-25;
  }


  /************************************************************************/
   /* ************************** HERO *********************************** */
  .hero-img-izq {
    @apply w-full relative md:border-secondary border-y-8 bg-[url('./assets/principal.png')];
    @apply md:h-[350px];
    
  }

  .hero-img-der  {
    @apply w-[50%] lg:w-[100%] md:border-secondary border-l-8  bg-[url('./assets/img/house7.jpg')];
    @apply md:h-[350px] ;
  }

  .hero-ttle {
    @apply p-5 text-4xl font-body font-extrabold text-center text-primary;
    @apply md:text-5xl lg:text-6xl ;
  
  }

  .hero-subttle {
    @apply text-center text-4xl font-body font-extrabold  text-primary;
    @apply md:text-4xl;
  }


  .hero-boton {
    @apply bg-primary absolute bottom-5 m-1 px-5 py-3 font-body font-bold  text-orange-500 rounded-3xl hover:bg-tertiary hover:bg-opacity-25;
    @apply text-sm ml-[17%] md:ml-[32%] lg:ml-[37%] xl:ml-[41%] ;
  }

    /***********|MOBILE|*****************/
  .hero-img-izq-cel {
    @apply h-[330px] border-y-8 border-secondary relative bg-[url('./assets/principal.png')];
  }

  .hero-img-der-cel  {
    @apply h-[220px] border-y-8 border-secondary content-center bg-[url('./assets/edificio.png')];
  }

  /********************************|CARROUSEL VIDEO|*********************************************/
  .carousel-ttle {
    @apply mb-8 text-xl font-body text-secondary ;
    @apply mt-4 ml-5 md:text-2xl md:mt-4 md:ml-3 xl:pt-2;
  }

  .carousel-container-pc{
    @apply md:flex hidden;
    @apply md:w-[760px] md:pl-6 lg:w-[1320px] lg:ml-1 xl:w-[96%] xl:ml-10 xl:pl-0;
  } 

  .crdblanca-txtblanco {
    @apply flex;
    @apply md:w-[270px] lg:w-[225px] xl:w-[275px]; 
  }

  .carouselvideo-slider{
    @apply h-full flex justify-start transition ease-out duration-700;
    @apply md:w-[760px] md:gap-20 lg:w-[1400px] lg:gap-20 xl:w-[1800px];
    
  }
  
  .carousel-content{
    @apply md:mx-0 overflow-x-auto overflow-y-auto;
  }

  .carousel-flecha-izq {
    @apply absolute z-30 left-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer;
    @apply xl:-left-5 xl:mr-0
  }

  .carousel-flecha-der {
    @apply absolute z-30 right-0 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400;
  }


  .crdblanca-opt {
    @apply rounded-2xl overflow-hidden scale-x-100 scale-y-100;
  }


    /*********|Mobile|**************/
  .carousel-container-cel{
    @apply items-center justify-center content-center text-center h-full p-4 w-full;
    @apply md:flex;
  }

  .carousel-flecha-izq-cel {
    @apply absolute z-30 left-0 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-400 cursor-pointer;
    @apply ml-2;
  }

  .carousel-flecha-der-cel {
    @apply absolute z-30 right-0 mr-8 focus:outline-none focus:bg-gray-400 focus:ring-2 focus:ring-offset-2 focus:ring-gray-400;
    @apply mr-4;
  }

  .crdblanca-txtblanco-cel {
    @apply flex relative h-[400px]; 
  }

  .crdblanca-opt-cel {
    @apply rounded-3xl overflow-hidden mr-4 md:mr-0;
  }

/************************************************************************/
/* ************************** BOTON FLOTANTE *********************************** */
  .boton-contacto {
    @apply flex items-center z-40 fixed bottom-4 right-4 bg-orange-400 bg-opacity-70 hover:bg-orange-400 hover:bg-opacity-30 text-secondary  justify-center p-2 font-semibold rounded-full;
    @apply md:text-xl;
  }

  .boton-logo {
    @apply p-1 right-0 w-11 h-10;
    @apply h-11 w-12 ;
  }

  /************************************************************************/
/* ************************** CAROUSEL LOGO *********************************** */
  .carousellogo-ttle {
    @apply mb-8 text-4xl font-body font-extrabold text-center text-secondary ;
    @apply lg:mb-5 md:text-4xl
  }

  .carousellogo-menu {
    @apply grid grid-cols-1 gap-4 text-gray-500 ;
    @apply md:grid-cols-3 lg:grid-cols-6
  }

  .carousellogo-menu-opt {
    @apply flex justify-center items-center;
  }

  .carousellogo-menu-img{
    @apply w-[150px] h-[120px] md:w-[150px] md:h-[120px];
  }

/************************************************************************/
/* *********************** CAROUSEL MULTIPLE ********************************* */
  .carouselmul-ttle-izq {
    @apply text-4xl font-body font-extrabold text-center uppercase text-secondary;
    @apply md:text-4xl;
  }

  .carouselmul-izq {
    @apply flex flex-col bg-primary w-full pb-10 ;
    }
    
  .carouselmul-ttle-der {
    @apply text-4xl font-body font-extrabold text-center uppercase text-secondary  ;
    @apply md:text-4xl
  }

  .carouselmul-der {
    @apply flex flex-col bg-primary w-full;
  }

  .carousel-bg{
    @apply bg-secondary h-[50 0px]
  }
}
