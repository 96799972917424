/****************************** DEFAULT| SM |********************************/
/**/
  .ttle-logo {
    color: rgb(0 32 92);
    font-size: 2rem;
    font-weight: 950;
    margin-right: 50px;
    margin-left: 120px;
  }
  
  .ttle-logo:hover .ttle-logo-change {
    color: rgb(221 175 37);
  }


  /****************************** TABLET| MD |********************************/
  @media only screen and (min-width: 768px)  and (max-width: 1023px) {
  .ttle-logo {
    color: rgb(0 32 92);
    font-size: 2rem;
    font-weight: 950;
    margin-right: 20px;
    margin-left: -30px;
  }

  .ttle-logo:hover .ttle-logo-change {
    color: rgb(221 175 37);
  }
}

  /****************************** LAPTOP| LG |********************************/
  @media only screen and (min-width: 1024px)  and (max-width: 1379px) {
    .ttle-logo {
      color: rgb(0 32 92);
      font-size: 3rem;
      font-weight: 950;
      margin-right: 20px;
      margin-left: 100px;
    }
  
    .ttle-logo:hover .ttle-logo-change {
      color: rgb(221 175 37);
    }
  }

    /****************************** DESKTOP| XL | ********************************/
    @media only screen and (min-width: 1380px) {
      .ttle-logo {
        color: rgb(0 32 92);
        font-size: 3rem;
        margin-right: 20px;
        margin-left: 100px;
      }
    
      .ttle-logo:hover .ttle-logo-change {
        color: rgb(221 175 37);
      }
    }
